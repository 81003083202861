import React, {  useEffect, useMemo, useState } from 'react';
import { AppEvents, PanelProps } from '@grafana/data';

import { SimpleOptions } from 'types';

import {  Button, Input, useTheme2, CustomScrollbar, ConfirmModal, Field } from '@grafana/ui';

import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ThemeProvider, createTheme } from '@material-ui/core';
//import { MqttClient, connect } from "mqtt/dist/mqtt"
import { getAppEvents } from '@grafana/runtime';
import mqtt from 'mqtt'



interface Props extends PanelProps<SimpleOptions> {}





interface VematDevicesTable{
  id: number;
  name: string|null;
  coef: number | null;
  isEdited: boolean;
  showError: boolean;
  [key: string]: any;
  origName: string|null;
  origCoef: number|null;
  origAddress: number;
  showModal: boolean;
}

interface IerrorData {
  [fieldName: string]: {
    message: string;
    show: boolean;
  };
}

interface Ierror {
  id: number;
  error: IerrorData;
}
export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  //console.log(data.request?.targets[0].datasource?.uid);
  //const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const appEvents = getAppEvents();

  //const [errors, setErrors] = useState<Error[]>([]);

  //const [editingError, setEditingError] = useState<number | null>(null);
  //const [editedMessage, setEditedMessage] = useState<string>('');
  //const [newErrorMessage, setNewErrorMessage] = useState('');
  //The first value,  is our current state.The second value is the function that is used to update our state.

  const [devices,setDevices] = useState<VematDevicesTable[]>([]);
  //const [modal,setModal] = useState<boolean>(false);

  const [formError, setFormError] = useState<Ierror[]>([]);
  const[update,setUpdate] = useState<boolean>(false);

  const [client,setClient] = useState<mqtt.MqttClient>();
  function generateRandomClientId(prefix: string, length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomPart = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    return `${prefix}${randomPart}`;
  }
  const mqttConnect = (host: string) => {
    const clientIdPrefix = 'client-';
  const clientIdLength = 10; // Adjust the length as needed
  const randomClientId = generateRandomClientId(clientIdPrefix, clientIdLength);
    //const brokerUrl = 'wss://46.36.40.68:6969';
    //setClient(connect(host));
  setClient(
    mqtt.connect(host, {
  clientId: randomClientId,
  username: 'vemat',
  password: 'vemat1337',
  rejectUnauthorized: false
  }));
  }
  useEffect(()=>{
    //console.log("F");
      mqttConnect('mqtts://vemat.edima.io:6969');
 
      //mqttConnect('wss://46.36.40.68:6969');
  // eslint-disable-next-line
  },[]);
  useEffect(()=>{
    //console.log("Connected");
    if(client!==undefined){
      client.subscribe("changeDeviceAddress_res",(err)=>{
        if(!err){
          //console.log("Subscribed to quantity changeDeviceAddress_res");
        }
      })
    }
  },[client]);
  

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        //setConnectStatus('Connected');
        
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        //setConnectStatus('Reconnecting');
      });
      client.on('message',  async (topic, message) => {
        const payload = { topic, message: message.toString() };
        //console.log(payload);
        if(topic === "changeDeviceAddress_res"){
          console.log("change device addr proc");
         let json = JSON.parse(payload.message);
            if(json.proc){
  //do update in db
  appEvents.publish({
    type: AppEvents.alertSuccess.name,
    payload: ["Adresa změněna"],
  });

  //console.log("json proc");
  const updateSql = `UPDATE devices SET address = '${json.newAddress}' WHERE address = ${json.address}`;
  //console.log(updateSql);
  const updateQuery = createQuery('now-1h', 'now', datasourceUID, updateSql);
  //console.log(updateSql);
  await fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
  reloadTable();
  //console.log("wohoo");
  //newData[index].isEdited=false;
  //setDevices(newData);
              //it happened
              //change value in db

            }else{
              //console.log("Failed");
              appEvents.publish({
                type: AppEvents.alertError.name,
                payload: ["Nepodařilo se změnit adresu, je zařízení v service módu?"],
              });
              //proc failed, could change address
              reloadTable();
            }
          
       
        //updateProgressAmount(payload);
        }
       
        //setPayload(payload);
      });
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  function sendMqtt(payload: any){

    if(client!==undefined){
    
      let jsonSend = payload;// {"command":"instantoff"};
      client.subscribe("VEMAT", (err) => {
        if (!err) {
          client.publish("VEMAT", JSON.stringify(jsonSend));
        }
      });
    }
    }
  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource:{
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };


  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier
  //const rawSql = `SELECT address,name,coef FROM devices order by address desc`;
  //const selectQuery = createQuery('now-1h', 'now', datasourceUID, rawSql);

   const headers = useMemo(() => {
    // Replace the following with your actual header initialization logic
    return {
      //Authorization: 'Bearer your_access_token',
      'Content-Type': 'application/json',
    };
  }, []);
  const reloadTable = () => {
    //document.dispatchEvent(new  CustomEvent('reloadDevices_sub'));
    console.log("RELOAD!");
    setUpdate(prevUp=>!prevUp);
  }
  //2. onclick event handler
  //3. edit / delete
  /*
  const reloadTable = useCallback(()=>{
    fetchData(grafanaApiBaseUrl, method, headers, selectQuery)
    .then(data => {
      if (data) {

        const ids = data.results.A.frames[0].data.values[0];
        const names = data.results.A.frames[0].data.values[1];
        const coefs = data.results.A.frames[0].data.values[2];

        const mappedData = ids.map((id: number, index: number) => ({
          id,
          name: names[index],
          coef: coefs[index],
          edited: false,
          showError:false,
          origAddress:id
        }));
        //map error array 
        const errList: Ierror[]=[];
        for (let i = 0; i < mappedData.length; i++) {
          const errorItem: Ierror = {
            id: mappedData[i].id,
            error: {},
          };
          errorItem.error['address'] = {
            message: "", // Set the error message for the 'name' field
            show: false,
          };
          // Dynamically set error information for specific fields
          errorItem.error['name'] = {
            message: "", // Set the error message for the 'name' field
            show: false,
          };
        
          errorItem.error['coef'] = {
            message: "", // Set the error message for the 'coef' field
            show: false,
          };
        
       
        
          errList.push(errorItem);
        }
      
        setFormError(errList);
        setDevices(mappedData);
        //napamovani nactenych dat do StateVariable
      }
    });
  }, [grafanaApiBaseUrl, method, headers, setDevices, setFormError,selectQuery]);

  //data fetching and processing
  useEffect(() => {
   reloadTable();
   return () => {
    // Cleanup code here
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/

  useEffect(() => {
    //console.log("use effect reload table...");
    //reloadTable();
    
    const createQuery = (
      from: string,
      to: string,
      datasourceUID: string | null = null,
      rawSql: string
    ) => {
      return {
        from: from,
        to: to,
        queries: [
          {
            refId: 'A',
            datasource: {
              uid: datasourceUID
            },
            //datasourceId: datasourceId,
            rawSql: rawSql,
            format: 'table',
          },
        ],
      };
    };
    
    const fetchData = (
      apiUrl: string,
      method: string,
      headers: Record<string, string>,
      body?: any
    ): Promise<any> => {
      return fetch(apiUrl, {
        method: method,
        headers: headers,
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          return null;
        });
    };
    const method = 'POST';
  
    //table select for vemat_devices 
   // const rawSql = `SELECT address,name,coef FROM devices order by address desc`;

    const sqlCommands = [`SELECT address,name,coef FROM devices order by name desc`];
    const query = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);
  
  const headers =  {
      'Content-Type': 'application/json',
    };
  
    const reload = async ()=>{
      await fetchData(grafanaApiBaseUrl, method, headers, query)
      .then(data => {
        if (data) {
  
          const ids = data.results.A.frames[0].data.values[0];
          const names = data.results.A.frames[0].data.values[1];
          const coefs = data.results.A.frames[0].data.values[2];
  
          const mappedData = ids.map((id: number, index: number) => ({
            id,
            name: names[index],
            coef: coefs[index],
            edited: false,
            showError:false,
            origAddress:id
          }));
          //map error array 
          const errList: Ierror[]=[];
          for (let i = 0; i < mappedData.length; i++) {
            const errorItem: Ierror = {
              id: mappedData[i].id,
              error: {},
            };
            errorItem.error['address'] = {
              message: "", // Set the error message for the 'name' field
              show: false,
            };
            // Dynamically set error information for specific fields
            errorItem.error['name'] = {
              message: "", // Set the error message for the 'name' field
              show: false,
            };
          
            errorItem.error['coef'] = {
              message: "", // Set the error message for the 'coef' field
              show: false,
            };
          
         
          
            errList.push(errorItem);
          }
          /*
      
          for(let i = 0 ; i < mappedData.length ; i++ ){
          
            errList.push({id:mappedData[i].id,message:"",show:false});
            
          }
          */
          setFormError(errList);
          setDevices(mappedData);
          //napamovani nactenych dat do StateVariable
        }
      });
      
    };
    console.log("RELOADDDDD");
    reload();
    return () => {
      // Cleanup code here
    };
  }, [update,datasourceUID,grafanaApiBaseUrl]);


  const handleSave = (index: number, row: VematDevicesTable) => {

    const newData = [...devices];
    const errorData = [...formError]

  // Perform validation
  
  if (row.name===null||!row.name.trim()) {
    // Error when name field is empty
   errorData[index].error["name"].message = "Chyba v názvu";
   errorData[index].error["name"].show = true;
    //console.log(errorData[index]['name'].message);
    //errorData[index].message = "Chyba v názvu";
    setFormError(errorData);
   // setFormError(prevState => ({...prevState, name: 'Name cannot be empty'}));
   // newData[index]["showError"]=true;
    //errorData[index][""]
    return;  // return early
  } else if (isNaN(Number(row.coef)) || Number(row.coef) < 0) {

    errorData[index].error["coef"].message = "Chyba v koeficientu (0.0)";
    errorData[index].error["coef"].show = true;
    // Error when coef is not a number
    //errorData[index].show = true;
    //errorData[index].message = "Chyba v koeficientu";
    setFormError(errorData);
   // setFormError(prevState => ({...prevState, coef: 'Coefficient should be a number'}));
    return;  // return early
  }

if(row.id!==row.origAddress){
//check for duplicity (new address)
  let _devices = [...devices];
  for (let i =0; i < _devices.length;i++){
    if(_devices[i].origAddress===row.id){
      errorData[index].error["address"].message = "Duplicitni adresa zařízení";
      errorData[index].error["address"].show = true;
      //duplucity exists
      //errorData[index].show = true;
      //errorData[index].message = "Duplicitni adresa zařízení";
      setFormError(errorData);
      return;
     
      
    
  }else{
   
  }
}

  let sendit = {"command":"changeDeviceAddress","address":row.origAddress,"newaddress":row.id};
  sendMqtt(sendit);
}




  //do update in db
  const updateSql = `UPDATE devices SET name = '${row.name}',coef = '${row.coef == null? 0 : row.coef}' WHERE address = ${row.origAddress}`;
  const updateQuery = createQuery('now-1h', 'now', datasourceUID, updateSql);
  //console.log(updateSql);
  fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
  newData[index].isEdited=false;
  setDevices(newData);
    //const newErrors = [...errors];
   // newErrors[index].message = editedMessage;
   // setErrors(newErrors);
   // setEditingError(null);

    
   appEvents.publish({
    type: AppEvents.alertSuccess.name,
    payload: ["Přidáno"],
  });
  errorData[index].error["name"].message = "";
errorData[index].error["name"].show = false;
errorData[index].error["coef"].message = "";
errorData[index].error["coef"].show = false;
errorData[index].error["address"].message = "";
errorData[index].error["address"].show = false;
  };


  /*
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedMessage(event.target.value);
  };
*/
const handleChange = (event: React.FormEvent<HTMLInputElement>, id: number, field: string) => {

  const newData = [...devices];
  const value = event.currentTarget.value;
  
  // Check if the field expects a number and if the value can be parsed as a number
  if (field === "id" && !isNaN(parseInt(value,10))) {
    newData[id][field] = parseFloat(value) as number; // Use type assertion here
  } else {
    newData[id][field] = value;
  }
  
  newData[id]["edited"] = true;

  const errs = [...formError];
  //errs[id].show = false;
  //errs[id].message = "";
  
  setFormError(errs);
  setDevices(newData);
  /*
  const newData = [...devices];
  newData[id][field] = event.currentTarget.value;
  newData[id]["edited"]=true;
  const errs = [...formError];
  errs[id].show=false;
  errs[id].message="";
  setFormError(errs);
  setDevices(newData);
  */
};

  const handleDelete = async (index: number, row: VematDevicesTable) => {
    //setModal(false);
    //todo delete cascade from db
    //console.log(row);
    //console.log(index);
    const deleteSql = `DELETE FROM devices WHERE address = ${row.id}`;
    //console.log(deleteSql);
    const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
    await fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
    //sleep(500);
    reloadTable();
    //const _devices = [...devices]
    //_devices.splice(index,1);
    //setDevices(_devices);
   // const updatedErrors = [...errors];
   // updatedErrors.splice(index, 1);
   // setErrors(updatedErrors);

  };

  const handleEdit = (index: number, row: VematDevicesTable)=>{
    let _devices = [...devices];
    _devices[index].isEdited=true;
    _devices[index].origCoef  = _devices[index].coef;
    _devices[index].origName = _devices[index].name;
    setDevices(_devices);
    //row.isEdited = true;
  };

  const handleCancel =  (index: number, row: VematDevicesTable)=>{
    let _devices = [...devices];
    _devices[index].isEdited=false;
    _devices[index].coef = _devices[index].origCoef;
   _devices[index].name = _devices[index].origName ;
   _devices[index].id = _devices[index].origAddress;
    //console.log(_devices[index]);
    setDevices(_devices);
  };

/*
  const handleAdd = () => {
    //console.log(errors[0].parameter);
    const variable = replaceVariables(options.variable);
    console.log("variable", variable);
    const insertSql = `INSERT INTO errors (param, error) VALUES ('${variable}', '${newErrorMessage}');`;
    console.log("insertSQL", insertSql);
    const insertQuery = createQuery('now-1h', 'now', datasourceUID, insertSql);
    console.log(insertSql);

    fetchData(grafanaApiBaseUrl, method, headers, insertQuery);
    
    
    console.log(variable);
    setErrors([...errors, { id: errors.length + 1, parameter: options.variable, message: newErrorMessage }]);
    setNewErrorMessage('');
  };
  */
  //funkce pro add -> jen nektere tabulky btw


  function sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    // Function to handle the event
    const handleEvent = (event: Event) => {
      console.log("recv event");
      //console.log("updateAmountOffList received" );
      sleep(100);
      setUpdate((prevUpdate) => !prevUpdate);
    };
  
    // Add the event listener when the component mounts
    document.addEventListener('reloadDevices', handleEvent);
  
    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('reloadDevices', handleEvent);
    };
     
  }, []); // Empty dependency array ensures this effect runs only once


  const table_theme = createTheme({
    overrides: {
      MuiFormControl:{
      root:{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.primary.contrastText
      }
      },
      MuiInputBase:{
        input:{
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText
        }
      },
      MuiTableCell: {
        body:{
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        head:{
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        root: {
           backgroundColor: theme.colors.background.primary,
           border: `1px solid ${theme.colors.background.primary}`,
           fontFamily: theme.typography.fontFamily,
           fontSize: theme.typography.fontSize,
           color: theme.colors.text.maxContrast,
           padding: '5px 16px', // Adjust the padding as needed
         }
       },
     },
  });
  /*
  const div_fix: React.CSSProperties = {
    position: 'absolute',
  };

  let div_error = {
   position: "absolute",
    top: "100%",
     left: 0,
      color: "red"
  }
*/
  return (

    <ThemeProvider theme={table_theme}>
      <CustomScrollbar>
    <TableContainer component={Paper} style={{backgroundColor: theme.colors.background.primary}}>
    
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Adresa</TableCell>
          <TableCell>Název</TableCell>
          <TableCell>Koeficient</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {devices.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
          
            <Field label="" invalid={Boolean(formError[index].error["address"].show)} error={formError[index].error["address"].message}>
             
              <Input 
          
               disabled={!row.isEdited}
                value={row.id}
                type='number'
                onChange={(e) => handleChange(e, index, 'id')}
              
                //invalid={Boolean(formError[index].show)}
                
                //helperText={formError[index].message}
              />
            
              </Field>
              
            </TableCell>


            <TableCell>
            <Field label="" invalid={Boolean(formError[index].error["name"].show)} error={formError[index].error["name"].message}>
              <Input
               disabled={!row.isEdited}
                value={row.name===null?"":row.name}
                onChange={(e) => handleChange(e, index, 'name')}
                //invalid={Boolean(formError[index].show)}
                //helperText={formError[index].message}
              />
                </Field>

              
            </TableCell>
            <TableCell>
            <Field label="" invalid={Boolean(formError[index].error["coef"].show)} error={formError[index].error["coef"].message}>

              <Input
                disabled={!row.isEdited}
                value={row.coef == null ? 0 : row.coef}
                onChange={(e) => handleChange(e, index, 'coef')}
                //invalid={Boolean(formError[index].show)}
                //helperText={formError[index].message}
               
              />
               </Field>
            </TableCell>
            
        
         <TableCell>
          <>
          {!row.isEdited&&
           <Button onClick={() => handleEdit(index, row)} variant={"primary"}>Edit</Button> }
            {row.isEdited&&
           <Button onClick={() => handleSave(index, row)} variant={"primary"}>Uložit</Button> }
           </>
           </TableCell>
      
                 
                 <TableCell>
                  <>
                 {row.isEdited&&
           <Button onClick={() => handleCancel(index, row)} variant={"destructive"}>Zrušit</Button> }
           </>
           <>
                 {!row.isEdited&&
           //<Button onClick={() => handleCancel(index, row)} variant={"primary"}>Zrušit</Button>
           <Button size="md" variant='destructive' onClick={()=>setDevices(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: true } : device))} >Smazat</Button>
         
           }
           </>
           <ConfirmModal isOpen={row.showModal}
            title={"Smazat zařízení z databáze"}
             body={"Opravdu chcete smazat zařízení z databáze?"}
              description={"Zařízení bude smazáno i s jeho záznamy."} 
              confirmText={"Smazat"} confirmButtonVariant={"primary"}
               dismissText={"Zrušit"} icon={"exclamation-triangle"}
                onConfirm={()=>handleDelete(index,row)} onDismiss={()=>setDevices(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: false } : device))} />

            </TableCell>
                

          </TableRow>
        ))}
      </TableBody>
    </Table>
   
  </TableContainer>
  </CustomScrollbar>
  </ThemeProvider>
  );
};
